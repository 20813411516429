.priceWrap{
    text-align: right;
    font-size: 2rem;
    .currency{
        vertical-align: super;
        font-size: 45%;
    }
    .price{
        font-weight: 700;
        padding-left: 4px;
        &.free{
            padding-left: 0;
            font-size: 75%;
        }
        .smaller{
            font-size:65%;
        }
    }
}

.converted {
    font-weight: 400;
    padding-left: 4px;
    font-size: 12px;
    vertical-align: top;
    &.currency {
        display: inline-block;
        vertical-align: inherit;
        font-size: inherit;
    }
    &.price {
        font-size: 12px;
    }
}
