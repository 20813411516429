.b2bWrapper {
    margin-top: 140px;
    min-height: 100%;
}

@media only screen and (max-width: (767px)){ 
    .b2bWrapper{
        .login-container{
            .form-container{
                &.first-panel-container,
                &.second-panel-container{
                    width: 100%;
                    left: 0;
                    transform: translateX(0%);
                }
                form{
                    padding: 0 20%;
                }
            }
            .overlay-container{
                display: none;
                .overlay{
                    .overlay-panel{
                        padding: 0;
                    }
                }
            }
        }
    }
}