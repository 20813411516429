$footer-background-color: #333333;
$footer-text-color: #cccccc;
$footer-bold-color: #ffffff;
$footer-links-color: #ffffff;

.footerWrapper{
    // background-color: $footer-background-color;
    min-height: auto;
    margin-top: 15px;
    .container{
        display: flex;
        align-items: stretch; // center
        justify-content: space-around;
        padding-top: 15px;
        padding-bottom: 15px;
        .column{
            &.left{text-align: left;}
            &.right{text-align: right;}
            &.center{text-align: center;}

            &.c1{width: 100%;}
            &.c2{width: 75%;}
            &.c3{width: 50%;}
            &.c4{width: 25%;}
            &.c5{width: 10%;}

            .extraPaddingTop{padding-top: 15px;}

            .links{
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    width: auto;
                    li{
                        display: inline-block;
                        margin-right: 15px;
                        a{
                            display: inline-block;
                            // color: $footer-links-color;
                            text-decoration: none;
                            font-weight: 700;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
                &.vertical{
                    ul{
                        li{
                            display: block;
                            width: 100%;
                            margin: 4px 0;
                            a{
                                font-size: 16px;
                            }
                        }
                    }
                }
                &.primary{
                    ul{
                        li{
                            a{
                                font-size: 18px;
                            }
                        }
                    }
                }
                &.secondary{
                    margin-bottom: 10px;
                    ul li a{
                        font-size: 14px;
                    }
                }
            }

            .text{
                // color: $footer-text-color;
                &.center{text-align: center;}
                .title{
                    // color: $footer-bold-color;
                }
                span{
                    font-weight: 400;
                }
                strong{
                    // color: $footer-bold-color;
                }
                a{
                    // color: $footer-bold-color;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                &.copyright{
                    font-weight: 700;
                }
                p{
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
            }

            .logo{
                a{
                    display: block;
                    text-decoration: none;
                    padding: 15px 0;
                    &:hover{
                        text-decoration: none;
                    }
                    img{
                        max-height: 90px;
                        max-width: 100%;
                        height: 55px;
                    }
                }
            }

        }
    }
}
