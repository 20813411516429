// $topbar-background-color: #4b2d81;
$topbar-box-shadow: 0px 1px 2px #333;
// $topbar-message-color: #ffffff;
$topbar-links-color: #ffffff;
// $topbar-bold-color: #ffffff;
// $topbar-menu-icon: #ffffff;

.topbarWrapper{
    // background-color: $topbar-background-color;
    padding: 7px 0;
    box-shadow: $topbar-box-shadow;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .menu, .cart{
            display: none;
            cursor: pointer;
            svg{
                width: 20px;
                // fill: $topbar-menu-icon;
            }
        }
        min-height: 58px;
        .logo{
            width: 20%;
            a{
                text-decoration: none;
                display: block;
                img{
                    max-width: 100%;
                    max-height: 46px;
                }
            }
        }
        .options{
            width: 100%;
            max-width: 1280px;
            margin: auto;
            .HeaderWrapper {
                width: 100%;
                text-align: center;
                .SwitchesWrapper {
                    padding-top: 5px;
                    margin-right: 6.5em;
                    display: inline-block;
                    .TopBarSwitches {
                        padding: 1px;
                        width: 302px;
                        background-color: rgb(175, 47, 44);
                        border-radius: 5px;
                        button {
                            width: 150px;
                            background-color: white;
                            color: black;
                            cursor: pointer;
                            &.selected {
                                cursor: initial;
                                background-color: rgb(175, 47, 44);
                                color: white;
                            }
                        }
                    }
                }
                .tatoButtons {
                    float: right;
                    @media (max-width: 991px) {
                        display: none;
                    }
                    cursor: pointer;
                    .tatoButton {
                        display: inline-block;
                        padding: 4px;
                        vertical-align: top;
                        .NoGuest > span, .CartItems > span {
                            border: 1px solid white;
                        }
                        .NoGuest > span {
                            background-color: orange;
                            color: white;
                        }
                        .CartItems > span {
                            background-color: rgb(249,212,72);
                            color: black;
                        }
                        .avatar {
                            background-color: rgb(175, 47, 44);
                            svg {
                                fill: #ffffff;
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                }
            }
            line-height: 26px;
            .message{
                // color: $topbar-message-color;
                text-align: right;
                span{
                    font-weight: 400;
                }
                strong{
                    // color: $topbar-bold-color;
                }
                a{
                    // color: $topbar-bold-color;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .links{
                ul{
                    list-style: none;
                    margin: 0;
                    width: auto;
                    float: right;
                    li{
                        display: inline-block;
                        margin-left: 15px;
                        a{
                            display: inline-block;
                            // color: $topbar-links-color;
                            text-decoration: none;
                            font-weight: 700;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Modal */
.attachGuest {
    .currentGuest {
        padding: 15px;
        border-bottom: 1px solid #bbb;
        background-color: #ccc;
        .guestDetails {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            h2, h3 {
                vertical-align: middle;
                padding: 0px;
                margin: 0px;
            }
            h3 {
                display: inline-block;
            }
            button {
                float: right;
            }
        }
    }
    .guestsList {
        padding: 15px;
        .guest {
            // padding: 1em;
            // height: 5em;
            border-bottom: 1px solid #bbb;
            line-height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p{
                margin: 0;
            }
            &:last-child{
                border-bottom-width: 0px;
            }
            .guestDetails {
                vertical-align: middle;
                display: inline-block;
            }
            .attachButton {
                border: 1px solid #333;
                padding: 0 10px;
            }
        }
        .SearchRegion {
            vertical-align: middle;
            h3 {
                display: inline-block;
                padding-right: .5em;
            }
            .Switch {
                width: 120px;
            }
            .SearchPattern {
                vertical-align: middle;
                margin-bottom: 4px;
                border: 1px solid #bbb;
                border-radius: 4px;
                padding-left: 1em;
                padding-right: 1em;
                width: 517px;
            }
            .ButtonsRegion {
                display: block;
                width: 100%;
                .attachButton {
                    text-align: center;
                    width: calc(50% - 2px);
                    border: 1px solid #333;
                    &:first-child {
                        margin-right: 4px;
                    }
                    &.primary {
                        background-color: #aa3535;
                        color: white;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }
}
