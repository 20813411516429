.generalModal {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    .header {
        padding: 25px 12px;
        // color: white;
        // background-color: #4b2d81;
        border-bottom: 1px solid lightgray;
        font-size: 24px;
        font-weight: bold;
        svg {
            float: right;
            &:hover{
                cursor:pointer;
            }
        }
    }
    .body {
        padding: 20px;
        .packageoptions {
            margin-top: 20px;
            .availability,
            .book,
            .stay {
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                .option {
                    width: 50%;
                    span {
                        font-weight: bold;
                    }
                }
            }
        }
        .name, .price {
            font-weight: bold;
            font-size: 16px;
            width: 50%;
            display: inline-block;
            margin-top: 20px;
            padding-top: 5px;
        }
        .priceWrap {
            .price {
                width: unset;
            }
        }
        .price {
            text-align: right;
        }
        .feesandtaxes {
            border-bottom:1px solid #eee;
            .name, .price {
                font-size: 14px;
                font-weight: normal;
                margin-top: 0;
            }
        }
        .cardImg{
            margin-top:15px;
        }
        .cardInputs{
            padding: 15px;
            .cardInput{
                display:inline-block;
                width:50%;
                p{
                    margin-block-end:0.5em;
                    padding-left:10px;
                    font-weight:bold;
                }
                .full{
                    margin-left:2%;
                    width:98%;
                }
                .date{
                    width:66%;
                    margin:0 2%;
                }
                div{
                    display:inline-block;
                    select{
                        width:100%;
                    }
                }
                input{
                    padding-left:10px;
                    margin-bottom:10px;
                    width:30%;
                    height:32px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                    margin-left:2%;
                }
                select{
                    width:20%;
                    margin-left: 10px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                    padding:5px;
                    height:32px;
                    background-color:white;
                }
            }        
        }
    }
    .content {
        overflow-y: auto;
        max-height: calc(70vh - 120px);
    }

    .footer {
        // background-color: white;
        border-top: 1px solid #e5e5e5;
        .primary,
        .secondary {
            margin: 20px;
            border: 1px solid transparent;
            border-radius: 0;
            text-align: center;
            text-decoration: none;
            // color: #ffffff;
            font-style: normal;
            font-size: 14px;
            height: auto;
            padding: 6px 12px;
            line-height: 14px;
            letter-spacing: -0.5px;
            &:hover{
                cursor:pointer;
            }
        }
        .primary{
            text-transform: uppercase;
            float: right;
            // background: linear-gradient(to bottom, #f3305e 0, #f3305e 50%, #f3305e 50%, #f3305e 100%);
            a{
                text-decoration: none;
                // color: white;
            }
        }
        .secondary {
            // background-color: #b02621;
        }
    }
    &:focus{
        outline: 0;
    }
}
