@media only screen and (min-width: $desktop){
    .searchWrapper{
        .mobilesearch{
            display:none;
        }
    }
    .viewWrapper{
        
    }
    .activitiesSection, .merchandiseSection, .liftTicketDaySection{
        .sidebar{
            .LoadingScreen{
                display:block;
            }
            .summaryCart{
                display:block;
            }
        }
        .summaryCart{
            display:none;
        }
        .mobileCart{
            display:none;
        }
        .LoadingScreen{
            display:none;
        }
    }
    .loadingsearch{
        color:white;
        padding-top:10px;
        height:38px;
        background-color:lightblue;
        width:100%;
        margin-bottom:-8px;
        text-align:center;
    }
    .mobileProduct{
        display:none;
    }
    .mobileCartSection{
        display:none;
    }
    .register{
        .registerSection{
            padding:15px;
            width:75%;
            margin-right:15px;
            .loginForm, .registerForm{
                .formFooter{
                    div{
                        .ctaLogin,.ctaRegister{
                            font-size: 1.35rem;
                        }
                    }
                }
            }
        }
    }
    .reset{
        .resetSection{
            padding:15px;
            width:75%;
            margin-right:15px;
        }
    }
    .payment{
        .paymentSection{
            padding:15px;
            width:75%;
            margin-right:15px;
        }
    }
    .cardModal, .terms, .removeModal, .taxesModal{
        width:750px;
        margin:auto;
        margin-top:10vh;
    }
    .cardModal:focus, .terms:focus, .removeModal:focus, .taxesModal:focus{
        outline:0;
    }
    .review{
        .reviewSection{
            padding:15px;
            width:75%;
            margin-right:15px;
        }
    }



    .suppliersSection{
        margin-top: 0px;
        // .sidebar{
        //     .map{
        //         background-color: grey;
        //         text-align: center;
        //         line-height: 200px;
        //     }
        //     .filter{
        //         .head{
        //             background-color: $lodging-filter-head-background-color;
        //             color: $lodging-filter-head-text-color;
        //             text-align: center;
        //             text-transform: uppercase;
        //             .title{
        //                 font-size: 14px;
        //                 font-weight: 700;
        //                 line-height: 2.2rem;
        //             }
        //         }
        //         .content{
        //             background-color: $lodging-filter-content-background-color;
        //             padding: 10px;
        //             .inputSearch{
        //                 margin: 0;
        //             }
        //         }
        //         &.byPrice{
        //             .content{
        //                 height: 75px;
        //                 .input-range{
        //                     margin: 20px 0;
        //                     .input-range__label{
        //                         color: $lodging-filter-slider-label-color;
        //                     }
        //                     .input-range__label--min{
        //                         left: 20px;
        //                     }
        //                     .input-range__label--max{
        //                         right: 20px;
        //                     }
        //                     .input-range__track{
        //                         background: $lodging-filter-slider-track-color;
        //                     }
        //                     .input-range__track--active{
        //                         background: $lodging-filter-slider-track-active-color;
        //                     }
        //                     .input-range__slider{
        //                         background: $lodging-filter-slider-track-point-color;
        //                         border-color: $lodging-filter-slider-track-point-color;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        .content{
            // set the Grid list to fill the whole page for mobile
            .results{
                .head{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .message{
                        width: 50%;
                        padding: 8px 15px;
                        font-size: 14px;
                    }
                    .sorting{
                        padding: 0 16px;
                        // border: 1px solid $lodging-sort-by-button-border-color;
                        border-radius: 2px;
                        margin: 4px;
                        padding: 0px;
                        nav {
                            padding: 0px;
                        }
                    }
                    .options{
                        padding: 0 16px;
                    }
                }
                .list{
                    .rooms{
                       display:flex;
                       padding:15px;
                    }
                    .item{
                        padding:10px;
                        // padding-bottom:30px;
                        width:calc(25% - 15px);
                        margin-right:20px;
                        margin-left:0px;
                        &:nth-child(4n){
                            margin-right:0px;
                        }
                        .multimedia{
                            .trustyou{
                                display:none;
                            }
                        }
                    }
                }
            }
        }
    }

    .supplierSection{
        .searchBox{
            display:none;
            
        }
    }


    .generalModal {
        width: 750px;
        margin: auto;
        margin-top: 10vh;
    }

    .viewWrapper{
        .cartSection{
            padding:15px 100px;
            // background-color:white;
            .topBar{
                width:100%;
                display:flex;
                align-items:center;
                .title{
                    width:70%;
                    font-size:1.5rem;
                }
            }
            .topBar, .checkout{
                .itinerary-id{
                    display: block;
                    text-align: right;
                    width: 30%;
                    .id, .save{
                        float: right;
                        clear: both;
                        font-size: 19px;
                    }
                }
            }
            .checkout{
                .itinerary-id{
                    width:unset;
                }
            }
            .products{
                .agrupation{
                    .product{
                        .content{
                            margin-left:15%;
                            padding:15px;
                            width:67%;
                        }
                        .sideinfo{
                            width:19%;
                            padding:15px;
                            .remove{
                                padding: 5px 10px;
                                min-width:155px;
                                svg{
                                    margin-right:5px;
                                }
                            }
                        }
                    }
                }
            }
            .bottom{
                .summary{
                    width:35%;
                }
            }
            .checkout{
                padding:15px;
            }
        }
    }
    
    .liftTicketsSection{
        .content{
            .results{
                width: calc(100% - 275px);
                .list{
                    min-height:704px;
                    .mobileSearch{
                        display:none;
                    }
                    .dayList{
                        display:none;
                    }
                }
            }
        }
    }
    .liftTicketDaySection{
        .results{
            padding:15px;
        }
    }
    .currentSearch{
        button{
            display:none;
        }
    }
    .options{
        .headers{
            display: flex;
        }
        .values{
            padding:15px;
            .product{
                display:flex;
            }
        }
    }

    .lodgingSection, .packageSection{
        .lodging{
            .content{
                .trustyou{
                    display:none;
                }
            }
        }
        
    }
    
}


