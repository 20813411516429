button{
    &.Primary, &.Secondary{
        margin: 4px;
        &.Primary{
            &.CTA {
                float: right;
            }
        }
        &.Secondary{
            &.CTA {
                float: left;
            }
        }
    }
}

.Guests, .Itineraries {
    margin-bottom: 1.5em;
    .SmallBox {
        height: 64px;
    }
    .SquarePaper {
        padding: 4px;
        border-radius: 3px;
        box-shadow: none;
    }
    .TablePaper {
        margin-top: 2em;
    }
    .Search {
        min-height: 64px;
        vertical-align: middle;
        padding: 20px;
        position: relative;
        .CenteredBox {
            text-align: center;
            button.AddButton {
                min-width: 32px;
                width: 32px;
                height: 32px;
                // background-color: #1ec8f9;
                // fill: white;
                float: right;
                margin: 0px;
                padding: 0px;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            .CriteriaSelector {
                // border: 1px solid #1ec8f9;
                border-width: 1px;
                border-style: solid;
                // color: white;
                // background-color: #1ec8f9;
                border-radius: 3px;
                padding-left: 1em;
                width: 160px;
                text-align: left;
                svg {
                    // color: white;
                }
            }
            .CriteriaSwitches {
                display: inline-block;
                padding: 1px;
                width: 302px;
                // background-color: #1ec8f9;
                border-radius: 5px;
                button {
                    margin-left: 0px;
                    margin-right: 0px;
                    width: 150px;
                    // background-color: white;
                    // color: black;
                    cursor: pointer;
                    &.selected {
                        cursor: initial;
                        // background-color: #1ec8f9;
                        // color: white;
                    }
                }
            }
            .CriteriaSearchBox {
                margin: 0px;
                padding-left: 1em;
                padding-right: 1em;
                input {
                    height: 34px;
                    padding: 0px;
                    padding-left: 8px;
                }
            }
            button {
                padding-left: 1em;
                padding-right: 1em;
                margin-left: 2px;
                margin-right: 2px;
                // &.SearchButton {
                //     background-color: #1ec8f9;
                //     color: white;
                // }
                // &.ClearButton {
                //     color: #1ec8f9;
                // }
            }
        }
    }
    .EditButton {
        min-width: 32px;
        width: 32px;
        height: 32px;
        padding: 0px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.Modal[class] {
    display: grid;
    text-align: center;
    margin-top: 2em;
    .content {
        max-height: calc(100vh + 250px);
        height: 70vh;
    }
    .InnerModal {
        margin: auto;
        padding: 2em;
        padding-top: 1em;
        padding-bottom: 0px;
        outline: none;
        width: 600px;
        max-height: 85vh;
        background-color: #ffffff;
        border-radius: 4px;
        position: relative;
        text-align: left;
        button.CloseModal {
            position: absolute;
            top: 5px;
            right: 5px;
            min-width: 16px;
            min-height: 16px;
            width: 24px;
            height: 24px;
            svg {
                width: 20px;
                height: 20px;
                fill: #333333;
            }
        }
        h2 {
            margin: 0px;
            padding-bottom: 1em;
        }
        .ModalContent {
            overflow-y: scroll;
            max-height: calc(79vh - 75px);
            margin-bottom: 6px;
            table {
                width: 100%;
                td {
                    &.Description {
                        text-align: right;
                        vertical-align: middle;
                    }
                    &.Required:after {
                        color: red;
                        content: '*';
                    }
                    &:first-child {
                        width: 200px;
                        padding-right: 2em;
                    }
                    &:last-child {
                        width: 70%;
                    }
                    .CreateGuestInput {
                        border: 1px solid rgba(0,0,0,0.2);
                        border-radius: 4px;
                        padding-left: 1em;
                        margin-top: 8px;
                    }
                }
            }
        }
        .ModalFooter, .Footer {
            position: sticky;
            bottom: 0px;
            left: 0px;
        }
    }
}

.attachGuest[class] {
    text-align: left;
    .InnerModal[class] {
        .ModalContent[class] {
            overflow-y: hidden;
            max-height: 100vh;
        }
    }
}
