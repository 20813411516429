/*$layout-body-background: #f0f0f0;

.main-application {
  background-color: $layout-body-background;
}*/

.viewWrapper {
  min-height: 38vh;
}

.clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}