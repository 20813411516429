@media only screen and (min-width: $tablet) and (max-width: ($desktop - 1px)){ 
    /* suppliers section styles */
    .MuiContainer-root.viewWrapper{
        .suppliersSection{
            .content{
                .results{
                    .list{
                        .item{
                            width:calc(50% - 10px);
                            &:nth-child(2n){
                                margin-right:0px;
                            }
                            margin-right:20px;
                        }
                    }
                }
            }
        }
    }
    .liftTicketsSection{
        .content{
            .results{
                .list{
                    .mobileSearch{
                        h2{
                            display:inline-block;
                            text-transform:uppercase;
                        }
                       .searchBox{
                           margin-left:20px;
                           width:calc(100% - 210px);
                           display:inline-block;
                           .LiftTicketDateRegion{
                               display:inline-block;
                               width:calc(100% - 100px);
                               .MuiInputBase-root{
                                   border: 1px solid black;
                                   padding-left:5px;
                                   margin-right:10px;
                               }
                               .MonthRegion{
                                   width:180px;
                                   .LiftTicketDateButton{
                                       margin-left: 0;
                                       margin-right: 0;
                                   }
                               }
                           }
                           .searchAction{
                               display:inline-block;
                               width:100px;
                               button{
                                   width:100%;
                               }
                           }
                       } 
                    }
                    .dayList{
                        display:none;
                    }
                    .Valid{
                        a{
                            .priceWrap{
                                font-size:1.15rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .options{
        .headers{
            display:flex;
        }
        .values{
            padding:15px;
            .product{
                display:flex;
            }
            .mobileProduct{
                display:none;
            }
        }
        
    }
    .MuiContainer-root.viewWrapper{
        .liftTicketDaySection{
            margin-top:-48px;
            .results{
                padding:15px;
            }
        }
    }
    
}

