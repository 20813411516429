.Header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 72px;
    // background-color: white;
    box-shadow: 2px 0px 2px rgba(0,0,0,0.4);
    z-index: 10;
    .InnerWrapper {
        max-width: 1280px;
        min-width: 768px;
        margin: auto;
        height: 100%;
        position: relative;
        padding-left: 2em;
        padding-right: 2em;
        table {
            width: 100%;
            height: 100%;
            td {
                max-width: 33.34%;
                width: 33.34%;
                img.Banner {
                    max-width: 100%;
                    max-height: 46px;
                    margin-top: 0px;
                    cursor: pointer;
                    @media (max-width: 1280px) {
                        margin-left: 1em;
                    }
                }
                .SwitchesWrapper {
                    .TopBarSwitches {
                        text-align: center;
                        margin: auto;
                        padding: 1px;
                        width: 302px;
                        // background-color: #1ec8f9;
                        border-radius: 5px;
                        button {
                            width: 150px;
                            // background-color: white;
                            // color: black;
                            cursor: pointer;
                            &.selected {
                                cursor: initial;
                                // background-color: #1ec8f9;
                                // color: white;
                            }
                        }
                    }
                }
                .UserInformation {
                    position: absolute;
                    top: 10px;
                    right: 0px;
                    .Picker {
                        .UserAvatar {
                            display: inline-block;
                            margin-left: 1em;
                            width: 50px;
                            height: 50px;
                        }
                        .UserDetails {
                            display: inline-block;
                            text-align: right;
                            vertical-align: top;
                            padding-top: 2px;
                        }
                    }
                }
            }
        }
    }
}

li.UserNameDetail {
    display: none;
}

.MuiInput-underline:before {
    display: none;
}
.MuiInput-underline:after {
    display: none;
}
