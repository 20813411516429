@media only screen and (max-width: ($desktop - 1px)){
    /* topbarWrapper Styles */
        .topbarWrapper{
            position:fixed;
            top:0px;
            max-width:100%;
            z-index:1100;
            // background-color: #4b2d81;
            padding: 7px 0;
            box-shadow: 0px 1px 2px #333;
            .container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                    max-height:53px;
                    min-height:unset;
                    .menu, .logo, .cart{
                        text-align:center;
                        display:unset;
                        width:33vw;
                        color:white;
                        a{
                        img{
                            max-height: 28px;
                        }
                        }
                    }
                    .menu{
                        padding-top:3px;
                        text-align:left;
                    }
                    .options{
                        .message, .links{
                            display:none;
                        }
                    }
                    .cart{
                        text-align:right;
                        .fa-stack{
                            top: 10px;
                            right: 15px;
                            height: 25px;
                            position: absolute;
                            width: 32px;
                        }
                        .fa-stack[data-count]:after {
                            position: absolute;
                            right: -20%;
                            top: -17%;
                            content: attr(data-count);
                            font-size: 70%;
                            padding: .5em;
                            border-radius: 999px;
                            line-height: .5em;
                            // color: white;
                            text-align: center;
                            font-weight: bold;
                            // background: red;
                        }
                    }
                }
            }
    
        /* NavWrapper Styles */
        .navWrapper{
                display:none;
        }
    
        /* searchWrapper Styles */
        .searchWrapper{
            .container{
                display:none;
                    padding-left: unset;
                    padding-right: unset;
                    .search{
                        display:none;
                    }
            }
            .mobilesearch{
                position:fixed;
                top:47px;
                z-Index:1100;
                display:block;
                width:100%;
                .searchform, .filter, .listMenuSortby{
                    font-size:18px;
                    text-align:center;
                    width:33.33%;
                    display:inline-block;
                    // background-color: #4c2f92;
                    font-weight:bold;
                    // color:white;
                    padding:8px;
                    .listItem{
                        .listItemText{
                            text-align:center;
                            span{
                                font-weight:bold;
                            }
                        }
                    }
                    p{
                        display:none;
                    }
                }
                .searchform, .filter{
                    border-right-width: 1px;
                    border-right-style: solid;
                }
                .filter, .listMenuSortby{
                    border-left-width: 1px;
                    border-left-style: solid;
                }
            }
        }
        .loadingsearch{
            display:none;
        }
    
        /* viewWrapper Styles */
        .MuiContainer-root.viewWrapper{
            .MuiGrid-spacing-xs-2{
                margin:0px;
            }
            top: 89px;
            position: relative;
            padding-left: 0px;
            padding-right: 0px;
        }
    
        /* LodgingCell Styles */
        .item{
            &.lodging{
                .multimedia{
                    min-width:35vw;
                    height:75px;
                    img{
                        width:100%;
                        height:100%;
                    }
                    .trustyou{
                        display:none;
                    }
                }
                .content{
                    min-height:80px;
                    width: 40vw;
                    .titleWrap{
                        .title{
                        font-size:0.875rem;
                        }
                    }
                    .locationWrap{
                        span, svg{
                            display:none;
                        }
                    }
                    .descriptionWrap{
                            display:none;
                    }
                    iframe{
                        height:40px;
                        width:150px;
                        border:none;
                    }
                    
                }
                .pricing{
                    position:relative;
                    top:30px;
                    .currency{
                        font-size:12px;
                    }
                    .price{
                        font-size:14px;
                    }
                    .main-cta{
                        .primary{
                            display:none;
                        }
                    }
                }
                
            }
        }
    
            /* lodging Styles */
    
        #sortby-menu{
            .MuiMenu-paper{
                margin-top:75px;
                width:100%;
            }
        }
        .lodgingSection{
            max-width: 100%;
            .sidebar{
                display: none;
            }
            .MuiGrid-root.content{
                flex-basis: unset;
                padding:0px;
                max-width:unset;
                
                .results{
                    .head{
                        .message{
                            // background-color: #EEE;
                            width:100%;
                            text-align:center;
                        }
                        .sorting{
                            display:none;
                        }
                        .options{
                            display:none;
                        }
                        .toggler{
                            display:none !important;
                        }
                    }
                    .list{
                        .rooms{
                            .lodging{
                                position:relative;
                                .content{
                                    width:75%;
                                }
                                .pricing{
                                    width:unset;
                                    position:absolute;
                                    bottom:15px;
                                    right:15px;
                                    .avg{
                                        display:none;
                                    }
                                }
                            }
                        }
                        margin-bottom:75px;
                    }
                }
                .fullmobilemap{
                    position:absolute;
                    height:calc(100vh - 109px);
                    width:100%;
                    .mobilemap{
                        position:fixed;
                        height:100%;
                        width:100%;
                        z-Index:100;
                    }
                    .togglemapbutton{
                        position:absolute;
                        bottom:10vh;
                        right:7vh;
                        border-radius:50%;
                        // background-color:#4b2d81;
                        z-Index:101;
                        // color:white;
                        padding:10px;
                    }
    
                }
            }
        }
        /* SearchScreen styles */
        .MuiContainer-root .searchingScreen {
            position:relative;
            top:-42px;
            height:calc(100vh - 195px);
            h1 {
                font-size: 3vh;
            }
        }
        /* footerWrapper styles */
        .footerWrapper{
            height: 140px;
            position:relative;
            width:100%;
            .container{
            .column{
                &.c2{
                    display: none;
                }
                &.c4{
                    width: 100%;
                }
                }
            }
        }
        .socketWrapper {
            height:40px;
            position: relative;
            width: 100%;
        }
    
    /* Activities styles */
        .activitiesSection{
            max-width:100%;
            .sidebar{
                .summaryCart{
                    display:none;
                }
                .LoadingScreen{
                    display:none;
                }
            }
            .LoadingScreen{
                display:none;
            }
            .content{
                flex-basis:100%;
                max-width:100%;
                padding:0px !important;
                .results{
                    .filters{
                        display:none;
                    }
                    .head{
                        .listMenuSortby{
                            display:none;
                        }
                        .message{
                            // background-color:#f0f0f0;
                            width:100% !important;
                            text-align:center;
                        }
                        .filters{
                            display:none;
                        }
                    }
                    .list{
                        margin-bottom:75px;
                        .groups{
                            .activities{
                                .content{
                                    min-width:unset;
                                    max-width:45%;
                                    .titleWrap{
                                        .title{
                                            font-size:16px;
                                        }
                                    }
                                    .descriptionWrap{
                                        display:none;
                                    }
                                }
                                .pricing{
                                    width:unset;
                                    .priceWrap{
                                        font-size:1.375rem;
                                    }
                                    .main-cta{
                                        display:none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /* Merchandise styles*/
        .MuiContainer-root.viewWrapper{
            .merchandiseSection{
                margin-top:-39px;
                .content{
                    flex-basis:unset;
                    max-width:unset;
                    padding:0px;
                    .results{
                        .head{
                            .listMenuSortby{
                                display:none;
                            }
                            .message{
                                // background-color:#f0f0f0;
                                min-width:100%;
                                text-align:center;
                            }
                            .filters{
                                display:none;
                            }
                        }
                        .list{
                            margin-bottom:75px;
                            .groups{
                                max-width:100vw;
                                .merchandise{
                                    .content{
                                        .titleWrap{
                                            .title{
                                                font-size:16px;
                                            }
                                        }
                                        .descriptionWrap{
                                            display:none;
                                        }
                                    }
                                    .pricing{
                                        .priceWrap{
                                            font-size:1.375rem;
                                        }
                                        .main-cta{
                                            display:none;
                                        }
                                    }
                                }
                                .merchandiseDetails{
                                    max-width:100%;
                                }
                            }
                        }
                    }
                }
                .sidebar{
                    .summaryCart{
                        display:none;
                    }
                    .LoadingScreen{
                        display:none;
                    }
                }
            }
        }
    
        /* Packages Section */
        .MuiContainer-root.viewWrapper{
            .packagesSection{
                margin-top:-39px;
                max-width:100%;
                .content{
                    flex-basis:unset;
                    max-width:unset;
                    padding:0px;
                    .results{
                        .head{
                            .listMenuSortby{
                                display:none;
                            }
                            .message{
                                // background-color:#f0f0f0;
                                min-width:100%;
                                text-align:center;
                            }
                        }
                        .list{
                            margin-bottom:75px;
                            .packages{
                                .package{
                                    display:block;
                                    text-align:center;
                                    .multimedia{
                                        width:100%;
                                        img{
                                            max-width:unset;
                                            width:100%;
                                        }
                                    }
                                    .content{
                                        width:100%;
                                        .titleWrap{
                                            margin:20px 0px;
                                            font-weight:bold;
                                            font-size:16px;
                                        }
                                        .descriptionWrap{
                                            display:none;
                                        }
                                    }
                                    .pricing{
                                        width:100%;
                                        .priceWrap{
                                            font-size:1.375rem;
                                        }
                                        .main-cta{
                                            width:100%;
                                            position:unset;
                                            button{
                                                width:100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .sidebar{
                    display:none;
                }
            }
        }
    
        /* SummaryCart styles*/
        .summaryCart{
            display:none;
        }
        
        /* Option Styles */
        .options{
            .values{
                .mobileProduct{
                    .productname{
                        display:block;
                    }
                    .productcontent{
                        display:flex;
                        align-items:center;
                        .date, .amount{
                            width: 50%;
                            .numericStepper{
                                float:right;
                                .Dropdown{
                                    // color:black;
                                }
                            }
                        }
                        .amount-nodate, .price-nodate{
                            width: 100%;
                            .priceWrap{
                                font-size: 1rem;
                            }
                            .numericStepper{
                                float:right;
                                display:flex;
                                .Dropdown{
                                    // color:black;
                                }
                            }
                        }
                    }
                    .priceWrap{
                        font-size: 1rem;
                        margin:10px 0px;
                    }
                    .book{
                        width:100%;
                        margin-bottom:15px;
                        margin-top:10px;
                        border: 1px solid transparent;
                        border-radius: 0;
                        text-align: center;
                        text-transform: uppercase;
                        text-decoration: none;
                        // color: #ffffff;
                        // background: linear-gradient(to bottom, #f3305e 0, #f3305e 50%, #f3305e 50%, #f3305e 100%);
                        font-weight: 700;
                        font-style: normal;
                        font-size: 14px;
                        padding: 7px;
                        line-height: 14px;
                        letter-spacing: -0.5px;
                    }
                }
                .mobileProduct ~ .mobileProduct{
                    padding-top:15px;
                }
            }
        }
        /* cartPage Styles */

        .emptyCart{
            .summaryCart{
                display:inherit;
                margin-bottom:75px;
            }
        }
        .cartSection{
            margin-top:-39px;
            .topBar{
                // background-color:white;
                text-align:center;
                border-bottom: 1px dashed #ccc;
                .title{
                    display:none;
                }
                .itinerary-id{
                    padding-top:10px;
                    font-size:20px;
                    // color:#e2001a;
                    width:100%;
                    text-align:center;
                    .id{
                        display:block;
                    }
                    button{
                        font-size:18px;
                        font-weight:normal;
                    }
                }
            }
            .products{
                .agrupation{
                    .header{
                        padding:5px 15px;
                    }
                    .product{
                        // background-color:white;
                        padding:15px;
                        border-bottom: 1px dashed #ccc;
                        background-color:white;
                        .multimedia{
                            display:none;
                        }
                        &:last-child{
                            border-bottom:none;
                        }
                        .content{
                            margin-left:0;
                            width:85%;
                            .supplierName{
                                font-weight:bold;
                                font-size:16px;
                            }
                            .productName{
                                color:#666;
                            }
                            .date{
                                width:50%;
                                display:inline-block;
                            }
                        }
                        .sideinfo{
                            width:30%;
                            text-align:right;
                            .price{
                                .savings{
                                    padding:35px 0px;
                                }
                                p{
                                    display:none;
                                }
                                .priceWrap{
                                    font-size:18px;
                                    position: absolute;
                                    top: 15px;
                                    right: 15px;
                                }
                            }
                            .remove{
                                // background-color:#ccc;
                                position:absolute;
                                bottom:15px;
                                right:15px;
                                min-width:unset;
                                width:fit-content;
                                padding:5px;
                                svg{
                                    margin-right:0px;
                                }
                                span{
                                    display:none;
                                }
                            }
                        }
                    }
                }
                
            }
            .bottom{
                margin-bottom:30px;
                .upselling{
                    display:none;
                }
                .summary{
                    width:100%;
                    margin-bottom:75px;
                    padding:15px;
                    .summaryName, .taxes{
                        // color:#545454;
                        font-weight:bold;
                        text-align:right;
                        width:50%;
                        display:inline-block;
                        .priceWrap{
                            text-align:right;
                            font-size:1.5rem;
                        }
                    }
                    .taxes{
                        // color:#428bca;
                    }
                }
            }
            .checkout{
                width:100%;
                position:fixed;
                bottom:0px;
                padding:0px;
                // background-color:rgb(231, 76, 60);
                z-index:10;
                button{
                    width:100%;
                    // color:white;
                    .priceWrap{
                        margin-left:10px;
                        font-size:16px;
                    }
                }
                .itinerary-id{
                    display:none;
                }
    
            }
        }
        /* Register Styles */
        .register{
            margin-top:-39px;
            .registerSection{
                padding:15px;
                width:100%;
                margin-bottom:75px;
                .loginForm, .registerForm{
                    .formFooter{
                        div{
                            button{
                                font-size: 1rem;
                                svg{
                                    display:none;
                                }
                            }
                        }
                    }
                }
            }
            .sidebar{
                display:none;
            }
        }
    
        /* Reset Styles */
        .reset{
            .resetSection{
                padding:15px;
                width:100%;
                margin-bottom:75px;
            }
            .sidebar{
                display:none;
            }
        }
        /* Payment Styles */
        .payment{
            margin-top:-39px;
            .paymentSection{
                padding:15px;
                width:100%;
                margin-bottom:75px;
            }
            .sidebar{
                display:none;
            }
        }
        .cardModal, .terms, .removeModal, .taxesModal, .generalModal{
            height:100vh;
            width:100%;
            margin-top:0px;
            .body{
                height:calc(100vh - 154px)
            }
        }
        /* Review Styles */
        .review{
            .reviewSection{
                padding:15px;
                margin-bottom:75px;
            }
            .sidebar{
                display:none;
            }
        }
        /* suppliers section styles */
        .MuiContainer-root.viewWrapper{
            .suppliersSection{
                margin-top:-47px;
                max-width:100%;
                margin-bottom:75px;
                .content{
                    padding:0px !important;
                    .results{
                        .list{
                            .rooms{
                            display:flex;
                            padding:15px 20px;
                            }
                            .item{
                                padding:10px;
                                // padding-bottom:30px;
                                margin-left:0px;
                                margin-right:0px;
                                position:unset;
                                .multimedia{
                                    height:unset;
                                }
                                .content{
                                    min-height:unset;
                                    .locationWrap{
                                        span, svg{
                                            display:inline-flex;
                                        }
                                    }
                                }
                                .pricing{
                                    position:unset;
                                }
                            }
                        }
                    }
                }
            }
        }
            /* supplier section styles */
        .MuiContainer-root.viewWrapper{
            .supplierSection{
                width:100%;
                margin-top:-46px;
                margin-bottom:75px;
                .sidebar{
                    display:none;
                }
                .content{
                    .title-address{
                        width:100%;
                    }
    
                    padding:0px;
                    width:100%;
                    max-width:unset;
                    flex-basis:unset;
                    .result{
                        .custombuttons{
                            display:none;
                        }
                        .imageswrapper{
                            .alice-carousel{
                            .alice-carousel__wrapper{
                                .alice-carousel__stage{
                                    .alice-carousel__stage-item{
                                        img{
                                            height:200px;
                                        }
                                    }
                                }
                            }
                            }
                        }
                        .header{
                            .priceWrap{
                                display:none;
                            }
                        }
                        .lodgingDetails{
                            .tabWrapper{
                                padding:0px;
                                .tabPanes{
                                    .tabPane{
                                        .rooms{
                                            .roomCell{
                                                .item{
                                                    .content{
                                                        .descriptionWrap{
                                                            display:none;
                                                        }
                                                    }
                                                    .pricing{
                                                        .main-cta{
                                                            display:none;
                                                        }
                                                        .priceWrap{
                                                            font-size:16px;
                                                        }
                                                    }
                                                }
                                                .roomDetails{
                                                    .tabWrapperRoom{
                                                        width:100%;
                                                        .tabPanes{
                                                            width:100%;
                                                            .tabPane{
                                                                .packages, .rooms{
                                                                    .header{
                                                                        .price{
                                                                            text-align:right;
                                                                        }
                                                                    }
                                                                    .package, .room{
                                                                        padding-bottom:15px;
                                                                        &:last-child{
                                                                            border-bottom:none;
                                                                        }
                                                                        .content{
                                                                            .booknow{
                                                                                width:100%;
                                                                                display:block;
                                                                                text-align:center;
                                                                                .book{
                                                                                    width:100%;
                                                                                    font-size:12px;
                                                                                }
                                                                            }
                                                                        }
                                                                        border-bottom:1px solid #ccc;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .searchBox{
                    position:fixed;
                    bottom:0px;
                    z-index:1;
                    width:100%;
                    height:48px;
                    // background-color:#cb1d40;
                    // color:white;
                    text-align:center;
                    padding-top:15px;
                    font-weight:bold;
                    font-size:13px;
                    .searchBar{
                        width:100%;
                        height:100%;
                    }
                    .MuiDrawer-root .MuiDrawer-paper{
                        // background-color: rgba(255, 255, 255, 0.5);
                        .searchDrawer{
                            margin-top:calc(100vh - 255px);
                            // background-color:white ;
                            height:calc(100vh - 51px);
                            .nights{
                                width:100%;
                                margin-top:15px;
                                display:flex;
                                align-items:center;
                                .numericStepper{
                                    .Dropdown{
                                        // color:black;
                                    }
                                }
                                span{
                                    text-align:center;
                                    width:50%;
                                }
                            }
                            
                            .guestsSelector{
                                display:block;
                                text-align:center;
                                .guest{
                                    display:flex;
                                    align-items:center;
                                    margin:15px 0px;
                                    span{                   
                                        width:50%;
                                    }
                                    .numericStepper{
                                        .Dropdown{
                                            // color:black;
                                        }
                                    }
                                }
                            }
                            .searchButton{
                                height:50px;
                                width:100%;
                                // background-color: #f3305e;
                                // color: #ffffff;
                                padding: 6px 15px;
                                border-radius: 1px;
                                font-size:18px;
                                font-weight:bold;
                            }
                        }
                        
                    }
                }
            }
        }
    .MuiContainer-root.viewWrapper{
        .liftTicketsSection{
            margin-top:-48px;
            margin-bottom:75px;
            max-width:100%;
            .content{
                width:100%;
                padding:0px;
                .SideBanner{
                    display:none;
                }
                width:100%;
                .results{
                    width:100%;
                    margin-right:0px;
                    .list{
                        padding:10px;
                        .mobileSearch{
                            margin-bottom:15px;
                        }
                        .currentSearch{
                            background-color:#DDDDDD;
                            padding-bottom:15px;
                        }
                    }
                }
            }       
            
        }     
    }
    .HeaderWrapper{
        display:none;
    }
    
    .MuiContainer-root.viewWrapper{
        .liftTicketDaySection{
            margin-top:-38px;
            .results{
                padding:0px;
            }
        }
    }
    
    .MuiContainer-root.viewWrapper{
        .liftTicketDaySection{
            margin-bottom:75px;
            max-width:100%;
            padding:0px;
            .content{
                width:100%;
                padding:0px;
                width:100%;
                max-width:unset;
                .results{
                    width:100%;
                    margin-right:0px;
                    .currentSearch{
                        padding:8px;
                        border: 1px solid #ccc;
                        background-color:#dddddd;
                        div{
                            display:inline-block;
                            width:calc(100% - 150px);
                        }
                    }
                    .list{
                        padding:10px;
                        .mobileSearch{
                            margin-bottom:15px;
                        }
                    }
                }
            }    
        }     
    }
    .HeaderWrapper{
        display:none;
    }
        
}