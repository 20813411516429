$mobile-category-background: #4b2d81;
$mobile-category-border: #ffffff;
$mobile-category-color: #ffffff;

$mobile-bottomaction-background: #333333;
$mobile-bottomaction-color: #ffffff;

$mobile-background: #4b2d81;

.generalDrawer{
    width: 100%;
    // background: $mobile-background;
    .header{
        height:25vh;
        // background-color: $mobile-background;
        img{
            height:100%;
            width:100%;
        }
    }
    &.categoriesMenu{
        .menu{
            overflow-y: scroll;
            height: calc(75% - 61px);
            .container{
                padding:0px;
                .option{
                    cursor: pointer;
                    // background-color: $mobile-category-background;
                    // color: $mobile-category-color;
                    text-align:center;
                    display:inline-block;
                    padding:6vh 0;
                    width: calc(33.33% - (1px));
                    // outline: 1px solid $mobile-category-border; /* use instead of border */
                    outline-width: 1px;
                    outline-style: solid;
                    margin-top: 1px;
                    margin-left: 1px;
                    margin-right:0px;
                    svg{
                        width: 100%;
                        // color: $mobile-category-color;
                        // fill: $mobile-category-color;
                        display: block;
                    }
                    .label{
                        text-decoration:none;
                        // color: $mobile-category-color;
                        display:block;
                    }
                    &:hover{
                        // background-color: lighten( $mobile-category-background, 10% );
                    }
                }
                .CartButtonWrapper{
                    display:none;
                }
            }
        }
        .bottommenu{
            display:flex;
            position:fixed;
            bottom:0px;
            width:100%;
            border-top: 1px solid transparent;
            // background-color: $mobile-bottomaction-background;
            height:61px;
            justify-content: flex-end;
            .options{
                width: 85%;
                display: inline-block;
                .bottomoption{
                    cursor: pointer;
                    text-align: center;
                    .optionText{
                        display:block;
                        // color: $mobile-bottomaction-color;
                    }
                    svg{
                        // color: $mobile-bottomaction-color;
                        // fill: $mobile-bottomaction-color;
                    }
                    padding-top:7px;
                    width:20vw;
                    display:inline-block;
                }
            }
            .closemenu{
                cursor: pointer;
                margin: 16px;
                // color: $mobile-bottomaction-color;
            }
        }
    }
}