.navWrapper{
    margin-top: 20px;
    .container{
        display: flex;
        align-items: center;
        position: relative;
        .CartButtonWrapper {
            position: absolute;
            bottom: 0px;
            @media (max-width: 919px) {
                right: 24px;
            } @media (min-width: 920px) {
                right: 32px;
            }                
        }
        button:focus { // removes weird outline on chrome when clicking any buttons
            outline: 0;
        }
        button{
            border: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90px;
            height: 70px;
            text-align: center;
            text-decoration: none;

            // background-color: #f2f7fd;
            // color: #333333;
            border-right: 1px solid transparent;

            svg{
                width: 32px;
            }
            span{
                width: 100%;
                text-transform: uppercase;
                font-weight: 600;
                text-align: center;
                font-size: 11px;
                white-space: initial;
                margin-top: 5px;
            }

            &.active{
                // background-color: #4b2d81;
                // color: #ffffff;
                svg{
                    // fill: #ffffff;
                }
            }
            &:hover{
                // background-color: #4b2d81;
                // color: #ffffff;
                cursor:pointer;
                svg{
                    // fill: #ffffff;
                }
            }
            &:last-child{
                border-right: 0 none;
                border-radius: 0 3px 0 0;
            }
            &:first-child{
                border-radius: 3px 0 0 0;
            }
            &.cartButton {
                // background-color: #4b2d81;
                // color: #ffffff;
                height: 40px;
                width: 200px;
                border-radius: 4px 4px 0px 0px;
            }
        }
    }
}

    .MuiDrawer-root{
        .MuiDrawer-paperAnchorBottom{
            height:100vh;
            // background-color: rgba(255, 255, 255, 0.5) ;
            .searchBox{
                .DateRangePicker, .SingleDatePicker{
                    margin-top:calc(100vh - (400px));
                }
                margin-left:0%;
                margin-top:88px;
                height:calc(100vh - 87px);
                // background-color:white;
                .SingleDatePicker, .DateRangePicker{
                    margin-right:0px;
                }
                text-align:center;
                .guestsSelector{
                    padding:35px 0px;
                    color:black;
                    display:block;
                    .guest{
                        background-color:unset;
                        display:flex;
                        align-items:center;
                        margin-left:25%;
                        span{
                            width:25%;
                            display:inline-block;
                        }
                        .numericStepper{
                            .Dropdown{
                                // color:black;
                            }
                        }
                    }

                }
                .bottomSearch{
                    height:48px;
                    position:fixed;
                    bottom:0px;
                    width:100%;
                    display:flex;
                    align-items:center;
                    .searchButton{
                        height:48px;
                        width:80vw;
                    }
                    .closemenu{
                        width: 20vw;
                    }
                }
            }
            .filters{
                margin-top:89px;
                height:calc(100vh - 127px);
                // color:black;
                padding:25px;
                // background-color:white;
                .filter{
                    height:initial;
                    .content{
                        min-height:40px;
                    }
                }
                .byLocation {
                    .content {
                        position: relative;
                        margin-bottom: 48px;
                        height: calc(100vh - 256px);
                        overflow-y: scroll;
                    }
                }
            }
            .filtersActivities{
                svg{
                    float:right;
                }
                margin-top:89px;
                height:calc(100vh - 89px);
                width:100%;
                // background-color:#4b2d81;
                text-align:center;
                padding:15px;
                .filters{
                    margin-top:calc(100vh - 355px);
                    // background-color:#4b2d81;
                    height:250px;
                    .filter{
                        border: 1px solid #333333;
                        // background-color:#f3f2f3;
                        padding: 15px;
                        display:inline-block;
                        width:100%;
                        margin-top:10px;
                        // color:black;
                        height:50px;
                        div{
                            display:inline-block;
                            margin-right:10px;
                        }
                        .selected{
                            font-size:smaller;
                            // color:#4b2d81;
                        }
                    }
                    .reset{
                        width:90%;
                        margin: 20px;
                        border: 1px solid transparent;
                        border-radius: 0;
                        text-align: center;
                        text-decoration: none;
                        // color: #ffffff;
                        font-style: normal;
                        font-size: 14px;
                        height: auto;
                        padding: 6px 12px;
                        line-height: 14px;
                        letter-spacing: -0.5px;
                        // background: linear-gradient(to bottom, #f3305e 0, #f3305e 50%, #f3305e 50%, #f3305e 100%);
                    }
                }
              }
            .drawerBottom{
                display:flex;
                align-items:center;
                position:fixed;
                bottom:0px;
                width:100%;
                height:48px;
                // background-color:white;
                .applyFilters{
                    // color:white;
                    font-size:14px;
                    font-weight:bold;
                    width:80vw;
                    height:48px;
                    // background-color:#4b2d81;
                }
                svg.closemenu{
                    width:20vw;
                    // background-color:white;
                }
            }

        }
    }
    .summaryCartDrawer{
        .MuiDrawer-paperAnchorRight{
            width:40vw;
            .cartSection{
                margin-top:-39px;
                .topBar{
                    // background-color:white;
                    text-align:center;
                    border-bottom: 1px dashed #ccc;
                    padding-top: 30px;
                    .title{
                        display:none;
                    }
                    .itinerary-id{
                        padding-top:10px;
                        font-size:20px;
                        // color:#e2001a;
                        width:100%;
                        text-align:center;
                        .id{
                            display:block;
                        }
                        button{
                            font-size:18px;
                            font-weight:normal;
                        }
                    }
                }
                .products{
                    .agrupation{
                        .header{
                            height: auto;
                            padding:5px 15px;
                        }
                        .product{
                            // background-color:white;
                            padding:15px;
                            border-bottom: 1px dashed #ccc;
                            background-color:white;
                            .multimedia{
                                display:none;
                            }
                            &:last-child{
                                border-bottom:none;
                            }
                            .content{
                                margin-left:0;
                                width:85%;
                                .supplierName{
                                    font-weight:bold;
                                    font-size:16px;
                                }
                                .productName{
                                    color:#666;
                                }
                                .date{
                                    width:50%;
                                    display:inline-block;
                                }
                            }
                            .sideinfo{
                                width:30%;
                                text-align:right;
                                .price{
                                    .savings{
                                        padding:35px 0px;
                                    }
                                    p{
                                        display:none;
                                    }
                                    .priceWrap{
                                        font-size:18px;
                                        position: absolute;
                                        top: 15px;
                                        right: 15px;
                                    }
                                }
                                .remove{
                                    // background-color:#ccc;
                                    position:absolute;
                                    bottom:15px;
                                    right:15px;
                                    min-width:unset;
                                    width:fit-content;
                                    padding:5px;
                                    svg{
                                        margin-right:0px;
                                    }
                                    span{
                                        display:none;
                                    }
                                }
                            }
                        }
                    }
                    
                }
                .bottom{
                    margin-bottom:30px;
                    .upselling{
                        display:none;
                    }
                    .summary{
                        width:100%;
                        margin-bottom:75px;
                        padding:15px;
                        .summaryName, .taxes{
                            // color:#545454;
                            font-weight:bold;
                            text-align:right;
                            width:50%;
                            display:inline-block;
                            .priceWrap{
                                text-align:right;
                                font-size:1.5rem;
                            }
                        }
                        .taxes{
                            // color:#428bca;
                        }
                    }
                }
                .checkout{
                    width:100%;
                    position:fixed;
                    bottom:0px;
                    padding:0px;
                    // background-color:rgb(231, 76, 60);
                    z-index:10;
                    button{
                        width:100%;
                        // color:white;
                        .priceWrap{
                            margin-left:10px;
                            font-size:16px;
                        }
                    }
                    .itinerary-id{
                        display:none;
                    }
        
                }
            }
        }
    }



