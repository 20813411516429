$socket-background-color: #111111;
$socket-text-color: #ffffff;
$socket-buildversion-color: #666666;

.socketWrapper{
    // background-color: $socket-background-color;
    height: 40px;
    .container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .poweredBySpotlio{
            display: flex;
            align-items: center;
            span{
                // color: $socket-text-color;
                padding-right: 10px;
            }
            a{
                display: inline-block;
                text-decoration: none;
                img{
                    display: block;
                    width: 87px;
                }
            }
        }
        .buildVersion{
            position: absolute;
            right: 32px;
            span{
                display: none;
                font-size: 10px;
                font-weight: 700;
                color: $socket-buildversion-color;
            }
        }
    }
}
