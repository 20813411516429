.CurrencySelectorButton {
    &.Link {
        text-transform: inherit;
        padding: 0px;
    }
    &.Avatar {
        font-size: 120%;
    }
}

.CurrenciesModal {
    text-align: left;
    cursor: initial;
    .CurrencyItem {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
        &.Selected {
            font-weight: bold;
        }
    }
}
