@media only screen and (max-width: ($tablet - 1px)){
/* suppliers section styles */
    .MuiContainer-root.viewWrapper{
        .suppliersSection{
            .content{
                .results{
                    .list{
                        .rooms{
                        }
                        .item{
                            width:100%;
                        }
                    }
                }
            }
        }
    }

    .liftTicketsSection{
        .SingleDatePicker{
            display:none;
        }
        .blurLoading{
            display:none;
        }
        .mobileSearch{
            h2{
                text-align:center;
            }
            .searchBox{
                .LiftTicketDateRegion{
                    display:block;
                    .MuiInput-root, .DaysRegion{
                        width:50% !important;
                        margin-bottom:15px;
                        text-align:center;
                    }
                    .MonthRegion{
                        text-align:center;
                        display:block;
                        width:100%;
                    }
                }
                .searchAction{
                    display:none;
                }
            }
        }
        .currentSearch{
            text-align:center;
            span{
                display:none;
            }
        }
        .dayList{
            margin-top:10px;
            margin-bottom:10px;
            border-top: 1px solid #000;
            text-align:center;
            padding-top:10px;
            .Valid{
                background-color:#7ed3f7;
                border-bottom: 1px solid #ccc;
                a{
                    color:black;
                    padding:5px 10px;
                    width:100%;
                    display:flex;
                    align-items:center;
                    text-decoration:none;
                    .priceWrap{
                        color:white;
                        display:inline-block;
                        font-size:24px;
                        width:35%;
                    }
                    .day{
                        div{
                            &:first-child{
                                font-weight:bold;
                            }
                        }
                        height:100%;
                        width: 35px;
                        margin-right:calc(65% - 35px);
                        display:inline-block;
                        &.weekend{
                            background-color:black;
                            color: white;
                        }
                    }
                }
                
            }
        }
    }
    .options{
        .headers{
            display:none;
        }
        .product{
            display:none;
        }
        .mobileProduct{
            padding:10px 15px;
            .productname{
                width:40%;
                .ageCategory{
                    font-size:18px;
                    font-weight:bold;
                    margin-bottom:5px;
                }
                .title{
                    display:flex;
                    align-items:center;
                    .groupTitle{
                        display:inline-block;
                        .cellTitle{
                            font-size:14px;
                            margin-right:10px;
                        }
                    }
                    svg{
                        &:hover{
                            fill:#428bca;
                            cursor:pointer;
                        }
                        display:inline-block;
                    }
                }        
            }
        }
    }
    
}




