.totalDueNow{
    margin-top:5px;
    border-top:1px dashed white;
    padding: 5px 0;
    .label{
        width:50%;
        text-align:right;
        display:inline-block;
        font-weight: bold;
    }
    .dueprice{
        width:50%;
        text-align:right;
        display:inline-block;
        padding-top: 4px;
        padding-bottom: 4px;
        vertical-align: middle;
        .priceWrap{
            font-size:1rem;
        }
    }
}
