.emptyCart{
    .noItems{
        min-height: 350px;
        background-color: #ffffff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        svg{
            text-align: center;
            font-size: 40px;
            color: #dfdfdf;
            line-height: 120px;
        }
        span{
            font-weight: 700;
            font-size: 16px;
            font-weight: 700;
            color: #afafaf;
            padding: 8px 0;
        }
    }
}
.cartSection{
    font-family: "Nunito","Open Sans","Roboto",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    // background-color:white;
    .topBar, .checkout{
        .itinerary-id{
            .save{
                text-transform: inherit;
                padding: 0;
                // color:#f3305e;
                &:hover{
                    background: transparent;
                    text-decoration: underline;
                }
            }
        }
        &.checkout{
            .itinerary-id{
                width: 100%;
                padding-bottom: 15px;
            }
        }
    }
    .products{
        .agrupation{
            margin: 15px 0px;
            .header{
                text-align:left;
                padding:5px 15px;
                // background-color:#4b2d81;
                // color:white;
                font-size:18px;
                font-weight:bold;
                border-bottom: 1px solid #dadada;
            }
            .product{
                // background-color: #EEE;
                display:flex;
                align-items: center;
                position:relative;
                border-bottom: 1px solid #dadada;
                .multimedia{
                    // background-color:#4b2d81;
                    width: 15%;
                    position:absolute;
                    bottom: 0px;
                    top:0px;
                    svg{
                        position:absolute;
                        top:calc(50% - (16px));
                        right:calc(50% - (16px));
                    }
                }
                .content{
                    .productName, .supplierName{
                        font-weight:bold;
                    }
                    .supplierName{
                        font-size:14px;
                    }
                    .productName{
                        font-size:16px;
                    }
                    .date, .qty{
                        // color:#7d7d7d;
                        font-size:14px;
                        span{
                            font-weight:bold;
                        }
                    }
                }
                .sideinfo{
                    padding-left:0px;
                    .remove{
                        float: right;
                        border-radius: 5px;
                        // color: white;
                        // background-color: #AAA;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        text-align: right;
                        &:hover{
                            cursor:pointer;
                        }
                    }
                    .price{ 
                        margin-bottom:10px;
                        .priceWrap{
                            font-size:1.5rem;
                        }
                        .savings{
                            font-weight:bold;
                            // color:#008800;
                            text-align:right;
                            font-size:16px;
                            .priceWrap{
                                font-size:16px;
                                position:unset;
                                display:inline-block;
                            }
                        }
                        p{
                            // color:#1F5393;
                            font-weight:bold;
                            text-align:right;
                            margin-block-start:0;
                        }
                    }
                }
            }
        }
    }
    .bottom{
        display:flex;
        align-items:center;
        .upselling{
            // background-color:#f3f3f3;
            width:65%;
            h3{
                &:first-of-type{
                    // color:#f3305e;
                }
                text-align:center;
            }
            .container{
                display: flex;
                align-items: center;
                justify-content: center;
                button{
                    background-color:inherit;
                    border: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 90px;
                    height: 70px;
                    text-align: center;
                    text-decoration: none;
                    // color: #333333;
                    border-right: 1px solid #D9E7EA;
                    svg{
                        // color:#4b2d81;
                    }
                    &:last-child{
                        border:none;
                    }
                    &:hover{
                        font-weight:bold;
                        cursor:pointer;
                        // color:#4b2d81;
                    }
                }
            }
        }
        .summary{
            .summaryName, .taxes{
                padding: 10px;
                // color:#545454;
                font-weight:bold;
                text-align:right;
                width:50%;
                display:inline-block;
                .priceWrap{
                    text-align:right;
                    font-size:1.5rem;
                }
                &.total{
                    // background-color:#dcdcdc;
                }
            }
            .taxes{
                // color:#428bca;
                &:hover{
                    text-decoration:underline;
                    cursor:pointer;
                }
            }
        }
    }
    .checkout{
        padding-right:0px;
        text-align:right;
        .goToCheckout{
            padding: 8px 25px;
            // color:white;
            // background-color:#e74c3c;
            // border: 1px solid #e74c3c;
            border-width: 1px;
            border-style: solid;
            border-radius:5px;
            text-transform:none;
            font-size:1.35rem;
            font-weight:700;
        }
        .goToCheckout:hover{
            // text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
            // background-color:white;
            // color:#e74c3c;
        }
    }
}